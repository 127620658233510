import React from 'react'
import helmetProps from './helmetProps'
import breakpoints, { isLarge } from './breakpoints'
import SplashContainer from './sections/SplashContainer'
import Library from './sections/Library'
import './Scope.global.scss'
import { Layout } from '../../Shared'

class Scope extends React.Component {
  render() {
    const {
      widthClass,
      width,
      splashImages,
      panoramaImages,
      moreInfoImages,
      libraryImages,
    } = this.props

    const isLargerThanMedium = isLarge(width)

    return (
      <div id="mobile">
        <SplashContainer images={splashImages} mediaType={widthClass} />
        <Library images={libraryImages} mediaType={widthClass} />
      </div>
    )
  }
}

export default props => (
  <Layout breakpoints={breakpoints}>
    <Scope {...props} />
  </Layout>
)

export { helmetProps }
