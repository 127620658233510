import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { IrisImg } from '../../../../Shared'
import { Filenames } from '../../filenames'

const Library = ({ images, mediaType }) => {
  const content = {
    intro: {
      title: 'Share with anyone, anywhere',
      body:
        'Manage panosets online using the Scope Library.',
      filename: Filenames.libPano,
      imgAlt: 'Pano Library',
    },
    steps: [
      {
        subtitle: 'Unlimited File Storage',
        body:
          'Upload an unlimited number of images into your library. Managed your panos centrally and have access to them anywhere.',
        filename: Filenames.mobileFlow1,
      },
    ],
  }

  const Step = ({ image, step }) => (
    <div className="step">
      <div className="step-image">
        <IrisImg file={image} alt="Step" />
      </div>
      <div className="detail">
        <p className="subtitle">{step.subtitle}</p>
        <p>{step.body}</p>
      </div>
    </div>
  )

  return (
    <section className={`library ${mediaType}`}>
      <div className="container content">
        <div className="intro scope-panel">
          <h1>{content.intro.title}</h1>
          <p>{content.intro.body}</p>
          <div className="steps">
            {content.steps.map((step, i) => (
              <Step image={images[step.filename]} step={step} key={i} />
            ))}
          </div>
          <div className="button-container">
            <Link to="/trial?product=scope">
              <button className="access-button">Access Library</button>
            </Link>
          </div>
        </div>

        <div className="scope-panel scope-panel-preview">
          <IrisImg
            file={images[content.intro.filename]}
            className="preview"
            alt={content.intro.imgAlt}
          />
        </div>
      </div>
    </section>
  )
}

Library.propTypes = {
  images: PropTypes.shape().isRequired,
  mediaType: PropTypes.string.isRequired,
}

export default Library
